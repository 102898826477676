import {
    SyncOutlined,
    SearchOutlined,
    ClearOutlined, UploadOutlined, LogoutOutlined, SettingOutlined,
    DeleteOutlined,
    DownloadOutlined,CloseOutlined,
    EditOutlined, MoreOutlined,  ExclamationCircleOutlined,LockOutlined,ReadOutlined,InfoCircleOutlined,
    CheckSquareOutlined,MinusCircleOutlined, PlusCircleOutlined,PlusOutlined,LoadingOutlined
} from "@ant-design/icons";

export {
    SyncOutlined,
    SearchOutlined,
    ClearOutlined, UploadOutlined, LogoutOutlined, SettingOutlined,
    DeleteOutlined,
    DownloadOutlined,CloseOutlined,
    EditOutlined, MoreOutlined, ExclamationCircleOutlined,LockOutlined,ReadOutlined,InfoCircleOutlined,
    CheckSquareOutlined,MinusCircleOutlined, PlusCircleOutlined ,PlusOutlined,LoadingOutlined
};